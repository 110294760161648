.webrtc{
  width: 100%;
  max-width: 100vw;
  position: relative;
  background-color: black;
}
.webrtc div{
  box-sizing: border-box;
}
.webrtc .closing{
  width: 100%;
  height: 100vh;
}
.webrtc .streams{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.webrtc .streams p {
  font-size: 1.4rem;
}
.webrtc .streams .stream-buttons{
  position: absolute;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  top: 1rem;
}
.webrtc .streams .stream-buttons button{
  border: none;
  padding: 0.2rem;
}
.webrtc .streams .stream-buttons img{
  width: 80px;
}

.wabrtc .streams .switch-button img,
.webrtc-ui-on .streams .switch-button img,
.webrtc-ui-off .streams .switch-button img{
  width: 80px;
  height: auto;
  position: absolute;
  top: 20px;
}

.wabrtc .streams .switch-button button,
.webrtc-ui-on .streams .switch-button button,
.webrtc-ui-off .streams .switch-button button{
  width: 100%;
  height: 100%;
  margin: 0;
}

.wabrtc .streams .switch-button p,
.webrtc-ui-on .streams .switch-button p,
.webrtc-ui-off .streams .switch-button p{
  margin: 0;
  font-size: medium;
  padding: 1rem;
}

.webrtc .hide{
  display: none;
}

.webrtc .my-cam-off{
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.webrtc .my-cam-off img{
  width: calc( 90px * var(--zep2-aspect));
}
.webrtc .calling{
  position: absolute;
  top: calc( 50% - 20px );
  left: calc( 50% - 20px );
  top: 0;
  left: 0;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  touch-action: none;
  pointer-events: none;
}
.webrtc .calling p{
  margin: 0;
  font-family: HiraKakuPro-W3;
  color: white;
}
.webrtc .streams .remote-stream,
.webrtc .streams .local-stream {
  position: relative;
}
.webrtc .streams .remote-stream video,
.webrtc .streams .local-stream  video {
  width: 100%;
  object-fit: contain;
}

.webrtc .test-log{
  border: solid 1px #ffffff21;
  color: white;
  font-size: 5px;
  filter: drop-shadow(0px 0px 2px black);
  position: absolute;
  bottom: 0%;
  right: 1em;
  overflow: scroll;
  height: 50%;
  touch-action: none;
  pointer-events: none;
}

.webrtc .streams .stream-buttons,
.wabrtc .streams .switch-button,
.webrtc .streams .nickname,
.webrtc .streams .reconnect-button{
  transition: opacity 0.3s;
}
.webrtc-ui-off .streams .stream-buttons,
.webrtc-ui-off .streams .switch-button,
.webrtc-ui-off .streams .nickname,
.webrtc-ui-off .streams .reconnect-button {
  opacity: 0;
  touch-action: none;
  pointer-events: none;
}
.webrtc-ui-on .streams .stream-buttons,
.webrtc-ui-on .streams .switch-button,
.webrtc-ui-on .streams .nickname,
.webrtc-ui-on .streams .reconnect-button {
  opacity: 1;
}
.webrtc #volume-ele,
.webrtc #other-bitrate,
.webrtc #connection-type,
.webrtc #my-bitrate{
  position: absolute;
  color: white;
  z-index: 3;
  filter: drop-shadow(0px 0px 2px black);
  white-space: pre-wrap;
}
.webrtc #my-bitrate{
  top: 0;
  left: 1em;
}
.webrtc #other-bitrate{
  top: 3em;
  left: 1em;
}
.webrtc #connection-type{
  top: 6em;
  left: 1em;
}
.webrtc .streams .nickname {
  width: 100%;
  min-height: auto;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 2rem;
  font-size: 1.6rem;
  color: white;
}

.webrtc .spectator-view .local-stream{
  transform: rotate(90deg);
}
.webrtc .spectator-view .remote-stream{
  transform: rotate(-90deg);
}
.webrtc .spectator-view video {
  object-fit: scale-down;
}

.webrtc .reconnect-button {
  position: absolute;
  width: 26rem;
  top: calc(50% + 4rem);
  right: 0;
  left: 0;
  margin: auto;
  background-color:rgba(255,255,255,0.3);
  border-radius: 2rem;
  padding: 1rem;
}
.webrtc .reconnect-button button {
  color: white;
}

.webrtc .battle-comment-modal {
  position: fixed;
  width: 700px;
  padding: 20px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 200;
}

.webrtc .battle-comment-modal h4 {
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}

.webrtc .battle-comment-modal textarea {
  width: 100%;
  height: 200px;
  border: 2px solid #000;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
}

.webrtc .battle-comment-modal .block-check {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px;
}

.webrtc .battle-comment-modal .block-check input {
  margin: 0 !important;
  width: 18px;
  height: 18px;
}

.webrtc .battle-comment-modal .block-check label {
  margin-left: 10px;
}

.webrtc .battle-comment-modal .button-group {
  display: flex;
  align-items: center;
  padding-top: 50px;
}

.webrtc .battle-comment-modal .button-group button {
  margin: 0;
}

.webrtc .battle-comment-modal .button-group .cancel-comment {
  width: fit-content;
  margin-right: auto;
  color: #1d72b3;
  font-size: 15px;
}

.webrtc .battle-comment-modal ul {
  padding-left: 0;
}

.webrtc .battle-comment-modal h5 {
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}

.webrtc .battle-comment-modal ul li {
  margin-bottom: 6px;
  list-style: none;
}

.webrtc .battle-comment-modal ul li label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.webrtc .battle-comment-modal ul li label input {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 5px;
}

.webrtc .battle-comment-modal .button-group .send-comment {
  margin-right: 30px;
  color: #ff0000;
  font-size: 16px;
}

.webrtc .battle-block-modal {
  position: fixed;
  width: 700px;
  padding: 20px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 200;
}

.webrtc .battle-block-modal h4 {
  font-size: 15px;
  text-align: left;
  color: grey;
  margin-bottom: 20px;
}

.webrtc .battle-block-modal .block-check {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px;
}

.webrtc .battle-block-modal .block-check input {
  margin: 0 !important;
  width: 18px;
  height: 18px;
}

.webrtc .battle-block-modal .block-check label {
  margin-left: 10px;
}

.webrtc .battle-block-modal .button-group {
  display: flex;
  align-items: center;
  padding-top: 50px;
}

.webrtc .battle-block-modal .button-group button {
  margin: 0;
}

.webrtc .battle-block-modal .button-group .cancel-comment {
  width: fit-content;
  margin-right: auto;
  color: #1d72b3;
  font-size: 15px;
}

.webrtc .battle-block-modal .button-group .send-comment {
  margin-right: 30px;
  color: #ff0000;
  font-size: 16px;
}

.webrtc .mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 190;
}

/* スマホの画面 */
@media screen and (max-width: 768px) {
  .webrtc .battle-comment-modal {
    width: 90vw;
    max-height: 95vh;
    overflow: scroll;
  }

  .webrtc .battle-block-modal {
    width: 90vw;
    max-height: 95vh;
    overflow: scroll;
  }

  .webrtc .battle-comment-modal h4 {
    margin-top: 0;
  }

  .webrtc .battle-comment-modal .block-check label {
    flex: 1;
  }

  .webrtc .battle-comment-modal .block-check {
    align-items: flex-start;
  }
}
.event-select {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
}

.event-select .select-banner {
	width: 50%;
	margin-top: 2rem;
}

.event-select .event-list {
	list-style-type: none;
	padding: 0;
}

.event-select .event-list li {
	display: flex;
	width: 50%;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
}

.event-select .event-list .session {
	font-size: 2.2rem;
	color: red;
	font-weight: bold;
	margin: auto;
}

.event-select .event-list .event {
	width: 50%;
	cursor: pointer;
}

.event-select .event-list .period {
	font-size: 1.8rem;
	font-weight: bold;
	margin: auto;
}

.event-select .button-format {
    padding: 0.6rem 0;
    background-color: black;
    color: white;
    font-size: 2rem;
}

@media only screen and (max-device-width: 480px) {
	.event-select {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
}

.event-select .select-banner {
	width: 86%;
	margin-top: 2rem;
}

.event-select .event-list {
	list-style-type: none;
	padding: 0;
}

.event-select .event-list li {
	display: flex;
	width: 85%;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
}

.event-select .event-list .session {
	font-size: 1.3rem;
	color: red;
	font-weight: bold;
	margin: auto;
}

.event-select .event-list .event {
	width: 65%;
	cursor: pointer;
}

.event-select .event-list .period {
	font-size: 1.1rem;
	font-weight: bold;
	margin: auto;
}

.event-select .button-format {
    padding: 0.6rem 0;
    background-color: black;
    color: white;
    font-size: 2rem;
}
}

.format-select {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
}

.format-select .select-banner {
	width: 50%;
	margin-top: 4rem;
}

.format-select .format-list {
	list-style-type: none;
	padding: 0;
}

.format-select .format-list li, .format-select .format-list li img {
	display: block;
	width: 50%;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
}

.format-select .button-format {
    padding: 0.6rem 0;
    background-color: black;
    color: white;
    font-size: 2rem;
}

@media only screen and (max-device-width: 480px) {
	.format-select {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
}

.format-select .select-banner {
	width: 86%;
	margin-top: 4rem;
}

.format-select .format-list {
	list-style-type: none;
	padding: 0;
}

.format-select .format-list li, .format-select .format-list li img {
	display: block;
	width: 80%;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
}

.format-select .button-format {
    padding: 0.6rem 0;
    background-color: black;
    color: white;
    font-size: 2rem;
}
}

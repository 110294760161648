.battle-wait {
    min-height : 100vh;
    background-color: black;
    max-width: var(--max-width);
    margin: auto;
}

.battle-wait .title {
    color: white;
    padding-bottom: 0.4rem;
    letter-spacing: 0.8rem;
    border-bottom:dotted;
    border-width: 3px;
}

.battle-wait .loading {
    width: 25%;
    margin-top: 5rem;
}

@media only screen and (max-device-width: 480px) {
	.battle-wait .loading {
    width: 50%;
    margin-top: 5rem;
}
}
.login {
    max-width: var(--max-width);
    margin: auto;
    text-align: center;
    align-items: center;
}
.login:before {
    background: url("../img/background_TOP_1080x1000.png") no-repeat left top;
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
} 
.login .logo {
    width: 435px;
    margin-top: 3rem;
} 
.login .form {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 3rem;
}
.login .input {
    width: 26%;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}
.login .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
    background-color: coral;
    color: white;
    border-radius: 1rem;
}
.login .logo-towa {
	display: flex;
    flex-flow: column;
    align-items: center;
}
.login .logo-towa .logo {
    width: 435px;
    margin-top: 3rem;
} 
.login .logo-towa .towa {
    width: 480px;
    margin-top: 1.5rem;
} 
.login .select-login {
	display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.login .select-login .select-login-button {
    position: relative;
	width: 220px;
    margin: 0 0.5rem;
}
.login .select-login .select-login-button .select-login-button-image {
	width: 100%;
    height: 100%;
}
.login .select-login .select-login-button .line-button-image {
    position: absolute;
    right: 22px;
    bottom: 13px;
	width: 55%;
    height: auto;
}
.login .how-play {
    width: 440px;
    margin: 1rem;
}
.login .login-button {
	width:65%;
}
.login .touroku-button {
	width:65%;
}
.login .skip-button {
	width:65%;
}
.login .werning-list{
	list-style-type: none;
	padding: 0;	
}
.login .werning-list li{
	display: flex;
	width: 75%;
	max-width: 300px;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
	background-color: rgba(255,255,255,0.6)
}
.login .werning-list .werning{
	width:15%;
	height:15%;
	margin: auto;
}
.login .werning-list .text{
	margin: auto;
	font-weight: bold;
	text-align: left;
}
.login .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.login .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.login .footer .navi li {
    display: inline-block;
}
.login .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.login .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.login .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.login .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.login .error{
    font-size: 2.4rem;
    color: red;
}

@media only screen and (max-device-width: 480px) {
.login {
    max-width: var(--max-width);
    margin: auto;
    text-align: center;
    align-items: center;
}
.login:before {
    background: url("../img/background_TOP.png") no-repeat left top;
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
} 
.login .logo {
    width: 80%;
    margin-top: 3rem;
} 
.login .form {
    margin-top: 3rem;
}
.login .input {
    width: 60%;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}
.login .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
    background-color: coral;
    color: white;
    border-radius: 1rem;
}
.login .logo-towa {
	display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
}
.login .logo-towa .logo {
    width: 80%;
    margin-top: 3rem;
} 
.login .logo-towa .towa {
    width: 90%;
    margin-top: 1.5rem;
} 
.login .select-login {
	display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.login .select-login .select-login-button {
    position: relative;
	width: 42%;
    margin: 0 0.1rem;
}
.login .select-login .select-login-button .select-login-button-image {
	width: 100%;
    height: 100%;
}
.login .select-login .select-login-button .line-button-image {
    position: absolute;
    right: 19px;
    bottom: 9px;
	width: 55%;
    height: auto;
}
.login .how-play {
    width: 80%;
    margin: 0.5rem;
}
.login .login-button {
	width: 50%;
    margin-bottom: 0.5rem;
}
.login .touroku-button {
	width:55%;
}
.login .skip-button {
	width:55%;
}
.login .saisettei-button {
	width:50%;
    margin: auto;
}
.login .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.login .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.login .footer .navi li {
    display: inline-block;
}
.login .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.login .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.login .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.login .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.login .error{
    font-size: 2.1rem;
    color: red;
    margin-bottom: 0.5rem;
}
}
.friend-match {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.friend-match button {
    padding: 0;
    margin-top: 0.5rem;
}
.friend-match .button-spectator-url {
    padding: 0.6rem 0;
    background-color: blue;
    color: white;
    font-size: 2rem;
}

.battle-select {
     min-height : 100vh;
     max-width: var(--max-width);
     margin: auto;
}

.announcement{
	font-size:1.5rem;
	color:red;
	font-weight:bold;
	margin-top:0;
	margin-bottom:0;
}
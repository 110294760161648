.dialog-root {
    position: fixed;
    top: 0;
    left: calc( 50% - var(--max-width) / 2 );
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto auto;
    max-width: var(--max-width);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 4;
}
.normal {
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-width: 70%;
}
.normal .textMessage {
	display: block;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
    white-space: pre-line;
}
.normal .url {
	display: block;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
    overflow-wrap: break-word;
    color: #0000FF;
    text-decoration: underline;
}
.normal .okBottun {
	display: inline-block;
	font-size: 1.5rem;
	color: red;
	margin-left: 3rem;
}
.normal .cancelBottun {
	display: inline-block;
	font-size: 1.5rem;
	color: blue;
}
.select-device{
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.select-device select{
    max-width: var(--max-width);
}
.select-device .item{
    font-size: 1.6rem;
    color: black;
}
.select-device .comment{
    margin-top: 1rem;
    padding: 10px;
    font-size: 1rem;
    color: black;
}
.select-device button{
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    color: black;
    border: none;
    font-size: 1.6rem;
}
.video-preview {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 16px;
    position: relative;
    padding-top: 56%;
}
.video-preview video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}
.volume-meter {
    width: 120px;
    height: 16px;
    margin: 0 auto;
    background: #000;
    margin-top: 16px;
}

@media (max-height: 500px) {
    .select-device {
        width: 50%;
    }
    .select-device .item {
        margin: 4px;
    }
    .select-device .comment {
        margin: 0;
        padding: 2px;
    }
    .video-preview {
        margin-top: 4px;
        width: 100%;
    }
    .select-device button {
        margin-top: 0.5rem;
        padding: 0;
        color: red;
    }
}
.home {
    min-height: 100vh;
    max-width: var(--max-width);
    margin: auto;
    text-align: center;
}
.home:before {
    background: url("../img/background_TOP_1080x1000.png") no-repeat left top;
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
} 
.home .logo {
    width: 435px;
    margin-top: 3rem;
} 
.home .play {
	display:block;
    width: 350px;
    margin-top: 3rem;
    margin: auto;
}
.home .logout {
    width: 300px;
    margin-top: 1rem;
}
.home .password-henkou {
    width: 300px;
}
.home .how-play {
    width: 440px;
    margin-top: 1rem;
}
.home .werning-list{
	list-style-type: none;
	padding: 0;	
}
.home .werning-list li{
	display: flex;
	width: 75%;
	max-width: 300px;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
	background-color: rgba(255,255,255,0.6)
}
.home .werning-list .wernning{
	width:15%;
	height:15%;
	margin: auto;
}
.home .werning-list .text{
	margin: auto;
	font-weight: bold;
	text-align: left;
}
.home .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.home .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.home .footer .navi li {
    display: inline-block;
}
.home .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.home .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.home .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.home .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.home .hello {
    font-size: 2.4rem;
}
.home .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
    background-color: coral;
    color: white;
    border-radius: 1rem;
}





@media only screen and (max-device-width: 480px) {
	.home {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
    text-align: center;
}
.home:before {
    background: url("../img/background_TOP.png") no-repeat left top;
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
} 
.home .logo {
    width: 80%;
    margin-top: 3rem;
} 
.home .play {
    width: 60%;
    margin-top: 1rem;
}
.home .logout {
    width: 50%;
    margin-top: 1rem;
}
.home .password-henkou {
    width: 50%;
}
.home .how-play {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
}
.home .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.home .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.home .footer .navi li {
    display: inline-block;
}
.home .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.home .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.home .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.home .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.home .hello {
    font-size: 2.4rem;
}
.home .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
    background-color: coral;
    color: white;
    border-radius: 1rem;
}
}
.box {
	font-size: 1rem;
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	padding: 2em;
	min-width: 20em;
	max-width: 70%;
	color: black;
	background-color: white;
	border-radius: 1em;
	transform: translate(-50%, -50%);
	outline: transparent;
}

.box .textMessage {
	display: block;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
}

.box .okBottun {
	display: inline-block;
	font-size: 1.5rem;
	color: red;
	margin-left: 3rem;
}

.box .cancelBottun {
	display: inline-block;
	font-size: 1.5rem;
	color: blue;
}

.Overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
}
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --max-width: 100%; 
  font-size: 62.5%;
}
li,
span,
pre,
h1,
h2,
h3,
h4,
h5,
p{
  color: var(--black);
}
a{
  text-decoration: none;
  color: inherit;
}
select,
button,
input[type="button"],
a {
cursor: pointer;
/* タブルタップでズームしない */
touch-action: manipulation;
font-family:-apple-system,BlinkMacSystemFont,"Helvetica Neue","游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif;
}
select:disabled,
button:disabled,
input[type="button"]:disabled,
a:disabled {
cursor: unset;
}
input[type="button"],
button{
outline: none;
background: none;
border: none;
}
body {
  /* max-width: var(--max-width); */
  min-height: 100vh;
  margin: auto;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  background-color: black;
}
button {
  display: block;
  margin: auto;
}

.title {
width: 24rem;
margin: auto;
padding-top: 8rem;
font-size: 2.4rem;
font-weight: bold;
border-bottom:solid;
border-width:3px;
}
.sub-title {
font-size: 1.6rem;
font-weight: bold;
letter-spacing: 0.2rem;
}
.description {
font-size: 1.4rem;
font-weight: bold;
letter-spacing: 0.1rem;
margin-top: 1.2rem;
}
.sankaku {
width: 8rem;
}
.button-normal,
.button-normal img {
  display: block;
  width: 37rem;
  margin: auto;
  margin-top: 2.6rem;
}
.button-back,
.button-back img {
  display: block;
  width: 27rem;
  margin: auto;
  margin-top: 4rem;
}
.required::after {
  content: "必須";
  color: #ffffff;
  background: #f22929;
  font-size: 0.7em;
  padding: 0.3em;
  border-radius: 0.5em;
  margin-left: 0.5em;
}



@media only screen and (max-device-width: 480px) {
.button-normal,
.button-normal img {
  display: block;
  width: 25rem;
  margin: auto;
  margin-top: 2.6rem;
}
.button-back,
.button-back img {
  display: block;
  width: 18rem;
  margin: auto;
  margin-top: 4rem;
}
}


@media only screen and (min-width: 768px) {
  a > img:hover,
  button > img:hover,
  .button-normal:hover,
  .card-list img:hover {
      opacity: .5;
      transition: .3s;
  }
}

@media only screen and (max-width: 768px) {
  a > img:active,
  button > img:active,
  .button-normal:active,
  .card-list img:active {
      opacity: .5;
      transition: .3s;
  }
}
.login {
    max-width: var(--max-width);
    margin: auto;
    text-align: center;
    align-items: center;
}
.login:before {
    background: url(/static/media/background_TOP_1080x1000.f4b560fb.png) no-repeat left top;
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
} 
.login .logo {
    width: 435px;
    margin-top: 3rem;
} 
.login .form {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 3rem;
}
.login .input {
    width: 26%;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}
.login .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
    background-color: coral;
    color: white;
    border-radius: 1rem;
}
.login .logo-towa {
	display: flex;
    flex-flow: column;
    align-items: center;
}
.login .logo-towa .logo {
    width: 435px;
    margin-top: 3rem;
} 
.login .logo-towa .towa {
    width: 480px;
    margin-top: 1.5rem;
} 
.login .select-login {
	display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.login .select-login .select-login-button {
    position: relative;
	width: 220px;
    margin: 0 0.5rem;
}
.login .select-login .select-login-button .select-login-button-image {
	width: 100%;
    height: 100%;
}
.login .select-login .select-login-button .line-button-image {
    position: absolute;
    right: 22px;
    bottom: 13px;
	width: 55%;
    height: auto;
}
.login .how-play {
    width: 440px;
    margin: 1rem;
}
.login .login-button {
	width:65%;
}
.login .touroku-button {
	width:65%;
}
.login .skip-button {
	width:65%;
}
.login .werning-list{
	list-style-type: none;
	padding: 0;	
}
.login .werning-list li{
	display: flex;
	width: 75%;
	max-width: 300px;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
	background-color: rgba(255,255,255,0.6)
}
.login .werning-list .werning{
	width:15%;
	height:15%;
	margin: auto;
}
.login .werning-list .text{
	margin: auto;
	font-weight: bold;
	text-align: left;
}
.login .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.login .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.login .footer .navi li {
    display: inline-block;
}
.login .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.login .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.login .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.login .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.login .error{
    font-size: 2.4rem;
    color: red;
}

@media only screen and (max-device-width: 480px) {
.login {
    max-width: var(--max-width);
    margin: auto;
    text-align: center;
    align-items: center;
}
.login:before {
    background: url(/static/media/background_TOP.93e2289d.png) no-repeat left top;
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
} 
.login .logo {
    width: 80%;
    margin-top: 3rem;
} 
.login .form {
    margin-top: 3rem;
}
.login .input {
    width: 60%;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}
.login .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
    background-color: coral;
    color: white;
    border-radius: 1rem;
}
.login .logo-towa {
	display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
}
.login .logo-towa .logo {
    width: 80%;
    margin-top: 3rem;
} 
.login .logo-towa .towa {
    width: 90%;
    margin-top: 1.5rem;
} 
.login .select-login {
	display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.login .select-login .select-login-button {
    position: relative;
	width: 42%;
    margin: 0 0.1rem;
}
.login .select-login .select-login-button .select-login-button-image {
	width: 100%;
    height: 100%;
}
.login .select-login .select-login-button .line-button-image {
    position: absolute;
    right: 19px;
    bottom: 9px;
	width: 55%;
    height: auto;
}
.login .how-play {
    width: 80%;
    margin: 0.5rem;
}
.login .login-button {
	width: 50%;
    margin-bottom: 0.5rem;
}
.login .touroku-button {
	width:55%;
}
.login .skip-button {
	width:55%;
}
.login .saisettei-button {
	width:50%;
    margin: auto;
}
.login .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.login .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.login .footer .navi li {
    display: inline-block;
}
.login .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.login .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.login .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.login .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.login .error{
    font-size: 2.1rem;
    color: red;
    margin-bottom: 0.5rem;
}
}
.home {
    min-height: 100vh;
    max-width: var(--max-width);
    margin: auto;
    text-align: center;
}
.home:before {
    background: url(/static/media/background_TOP_1080x1000.f4b560fb.png) no-repeat left top;
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
} 
.home .logo {
    width: 435px;
    margin-top: 3rem;
} 
.home .play {
	display:block;
    width: 350px;
    margin-top: 3rem;
    margin: auto;
}
.home .logout {
    width: 300px;
    margin-top: 1rem;
}
.home .password-henkou {
    width: 300px;
}
.home .how-play {
    width: 440px;
    margin-top: 1rem;
}
.home .werning-list{
	list-style-type: none;
	padding: 0;	
}
.home .werning-list li{
	display: flex;
	width: 75%;
	max-width: 300px;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
	background-color: rgba(255,255,255,0.6)
}
.home .werning-list .wernning{
	width:15%;
	height:15%;
	margin: auto;
}
.home .werning-list .text{
	margin: auto;
	font-weight: bold;
	text-align: left;
}
.home .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.home .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.home .footer .navi li {
    display: inline-block;
}
.home .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.home .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.home .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.home .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.home .hello {
    font-size: 2.4rem;
}
.home .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
    background-color: coral;
    color: white;
    border-radius: 1rem;
}





@media only screen and (max-device-width: 480px) {
	.home {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
    text-align: center;
}
.home:before {
    background: url(/static/media/background_TOP.93e2289d.png) no-repeat left top;
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
} 
.home .logo {
    width: 80%;
    margin-top: 3rem;
} 
.home .play {
    width: 60%;
    margin-top: 1rem;
}
.home .logout {
    width: 50%;
    margin-top: 1rem;
}
.home .password-henkou {
    width: 50%;
}
.home .how-play {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
}
.home .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.home .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.home .footer .navi li {
    display: inline-block;
}
.home .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.home .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.home .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.home .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.home .hello {
    font-size: 2.4rem;
}
.home .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
    background-color: coral;
    color: white;
    border-radius: 1rem;
}
}
.nickname {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.nickname input[type="text"] {
    width: 37rem;
    height: 3.2rem;
    border: none;
    border-radius: 0.4rem;
    font-size: 1.6rem;
}

@media only screen and (max-device-width: 480px) {
.nickname {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.nickname input[type="text"] {
    width: 22rem;
    height: 3.2rem;
    border: none;
    border-radius: 0.4rem;
    font-size: 1.6rem;
}	
}

.battle-select {
     min-height : 100vh;
     max-width: var(--max-width);
     margin: auto;
}

.announcement{
	font-size:1.5rem;
	color:red;
	font-weight:bold;
	margin-top:0;
	margin-bottom:0;
}
.dialog-root {
    position: fixed;
    top: 0;
    left: calc( 50% - var(--max-width) / 2 );
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto auto;
    max-width: var(--max-width);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 4;
}
.normal {
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-width: 70%;
}
.normal .textMessage {
	display: block;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
    white-space: pre-line;
}
.normal .url {
	display: block;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
    overflow-wrap: break-word;
    color: #0000FF;
    text-decoration: underline;
}
.normal .okBottun {
	display: inline-block;
	font-size: 1.5rem;
	color: red;
	margin-left: 3rem;
}
.normal .cancelBottun {
	display: inline-block;
	font-size: 1.5rem;
	color: blue;
}
.select-device{
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.select-device select{
    max-width: var(--max-width);
}
.select-device .item{
    font-size: 1.6rem;
    color: black;
}
.select-device .comment{
    margin-top: 1rem;
    padding: 10px;
    font-size: 1rem;
    color: black;
}
.select-device button{
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    color: black;
    border: none;
    font-size: 1.6rem;
}
.video-preview {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 16px;
    position: relative;
    padding-top: 56%;
}
.video-preview video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}
.volume-meter {
    width: 120px;
    height: 16px;
    margin: 0 auto;
    background: #000;
    margin-top: 16px;
}

@media (max-height: 500px) {
    .select-device {
        width: 50%;
    }
    .select-device .item {
        margin: 4px;
    }
    .select-device .comment {
        margin: 0;
        padding: 2px;
    }
    .video-preview {
        margin-top: 4px;
        width: 100%;
    }
    .select-device button {
        margin-top: 0.5rem;
        padding: 0;
        color: red;
    }
}
.friend-match {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.friend-match button {
    padding: 0;
    margin-top: 0.5rem;
}
.friend-match .button-spectator-url {
    padding: 0.6rem 0;
    background-color: blue;
    color: white;
    font-size: 2rem;
}

.event-select {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
}

.event-select .select-banner {
	width: 50%;
	margin-top: 2rem;
}

.event-select .event-list {
	list-style-type: none;
	padding: 0;
}

.event-select .event-list li {
	display: flex;
	width: 50%;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
}

.event-select .event-list .session {
	font-size: 2.2rem;
	color: red;
	font-weight: bold;
	margin: auto;
}

.event-select .event-list .event {
	width: 50%;
	cursor: pointer;
}

.event-select .event-list .period {
	font-size: 1.8rem;
	font-weight: bold;
	margin: auto;
}

.event-select .button-format {
    padding: 0.6rem 0;
    background-color: black;
    color: white;
    font-size: 2rem;
}

@media only screen and (max-device-width: 480px) {
	.event-select {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
}

.event-select .select-banner {
	width: 86%;
	margin-top: 2rem;
}

.event-select .event-list {
	list-style-type: none;
	padding: 0;
}

.event-select .event-list li {
	display: flex;
	width: 85%;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
}

.event-select .event-list .session {
	font-size: 1.3rem;
	color: red;
	font-weight: bold;
	margin: auto;
}

.event-select .event-list .event {
	width: 65%;
	cursor: pointer;
}

.event-select .event-list .period {
	font-size: 1.1rem;
	font-weight: bold;
	margin: auto;
}

.event-select .button-format {
    padding: 0.6rem 0;
    background-color: black;
    color: white;
    font-size: 2rem;
}
}

.box {
	font-size: 1rem;
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	padding: 2em;
	min-width: 20em;
	max-width: 70%;
	color: black;
	background-color: white;
	border-radius: 1em;
	transform: translate(-50%, -50%);
	outline: transparent;
}

.box .textMessage {
	display: block;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
}

.box .okBottun {
	display: inline-block;
	font-size: 1.5rem;
	color: red;
	margin-left: 3rem;
}

.box .cancelBottun {
	display: inline-block;
	font-size: 1.5rem;
	color: blue;
}

.Overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
}
.free-match {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.free-match .select-banner {
    width: 55%;
    margin-top: 4rem;
}
.free-match .card-list{
    list-style-type: none;
    padding: 0;
}
.free-match .card-list li,
.free-match .card-list li img {
    display: block;
    width: 50%;
    cursor: pointer;
    margin: auto;
    margin-bottom: 2rem;
}


@media only screen and (max-device-width: 480px) {
 .free-match {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.free-match .select-banner {
    width: 86%;
    margin-top: 4rem;
}
.free-match .card-list{
    list-style-type: none;
    padding: 0;
}
.free-match .card-list li,
.free-match .card-list li img {
    display: block;
    width: 80%;
    cursor: pointer;
    margin: auto;
    margin-bottom: 2rem;
}
}
.webrtc{
  width: 100%;
  max-width: 100vw;
  position: relative;
  background-color: black;
}
.webrtc div{
  box-sizing: border-box;
}
.webrtc .closing{
  width: 100%;
  height: 100vh;
}
.webrtc .streams{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.webrtc .streams p {
  font-size: 1.4rem;
}
.webrtc .streams .stream-buttons{
  position: absolute;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  top: 1rem;
}
.webrtc .streams .stream-buttons button{
  border: none;
  padding: 0.2rem;
}
.webrtc .streams .stream-buttons img{
  width: 80px;
}

.wabrtc .streams .switch-button img,
.webrtc-ui-on .streams .switch-button img,
.webrtc-ui-off .streams .switch-button img{
  width: 80px;
  height: auto;
  position: absolute;
  top: 20px;
}

.wabrtc .streams .switch-button button,
.webrtc-ui-on .streams .switch-button button,
.webrtc-ui-off .streams .switch-button button{
  width: 100%;
  height: 100%;
  margin: 0;
}

.wabrtc .streams .switch-button p,
.webrtc-ui-on .streams .switch-button p,
.webrtc-ui-off .streams .switch-button p{
  margin: 0;
  font-size: medium;
  padding: 1rem;
}

.webrtc .hide{
  display: none;
}

.webrtc .my-cam-off{
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.webrtc .my-cam-off img{
  width: calc( 90px * var(--zep2-aspect));
}
.webrtc .calling{
  position: absolute;
  top: calc( 50% - 20px );
  left: calc( 50% - 20px );
  top: 0;
  left: 0;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  touch-action: none;
  pointer-events: none;
}
.webrtc .calling p{
  margin: 0;
  font-family: HiraKakuPro-W3;
  color: white;
}
.webrtc .streams .remote-stream,
.webrtc .streams .local-stream {
  position: relative;
}
.webrtc .streams .remote-stream video,
.webrtc .streams .local-stream  video {
  width: 100%;
  object-fit: contain;
}

.webrtc .test-log{
  border: solid 1px #ffffff21;
  color: white;
  font-size: 5px;
  filter: drop-shadow(0px 0px 2px black);
  position: absolute;
  bottom: 0%;
  right: 1em;
  overflow: scroll;
  height: 50%;
  touch-action: none;
  pointer-events: none;
}

.webrtc .streams .stream-buttons,
.wabrtc .streams .switch-button,
.webrtc .streams .nickname,
.webrtc .streams .reconnect-button{
  transition: opacity 0.3s;
}
.webrtc-ui-off .streams .stream-buttons,
.webrtc-ui-off .streams .switch-button,
.webrtc-ui-off .streams .nickname,
.webrtc-ui-off .streams .reconnect-button {
  opacity: 0;
  touch-action: none;
  pointer-events: none;
}
.webrtc-ui-on .streams .stream-buttons,
.webrtc-ui-on .streams .switch-button,
.webrtc-ui-on .streams .nickname,
.webrtc-ui-on .streams .reconnect-button {
  opacity: 1;
}
.webrtc #volume-ele,
.webrtc #other-bitrate,
.webrtc #connection-type,
.webrtc #my-bitrate{
  position: absolute;
  color: white;
  z-index: 3;
  filter: drop-shadow(0px 0px 2px black);
  white-space: pre-wrap;
}
.webrtc #my-bitrate{
  top: 0;
  left: 1em;
}
.webrtc #other-bitrate{
  top: 3em;
  left: 1em;
}
.webrtc #connection-type{
  top: 6em;
  left: 1em;
}
.webrtc .streams .nickname {
  width: 100%;
  min-height: auto;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 2rem;
  font-size: 1.6rem;
  color: white;
}

.webrtc .spectator-view .local-stream{
  transform: rotate(90deg);
}
.webrtc .spectator-view .remote-stream{
  transform: rotate(-90deg);
}
.webrtc .spectator-view video {
  object-fit: scale-down;
}

.webrtc .reconnect-button {
  position: absolute;
  width: 26rem;
  top: calc(50% + 4rem);
  right: 0;
  left: 0;
  margin: auto;
  background-color:rgba(255,255,255,0.3);
  border-radius: 2rem;
  padding: 1rem;
}
.webrtc .reconnect-button button {
  color: white;
}

.webrtc .battle-comment-modal {
  position: fixed;
  width: 700px;
  padding: 20px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 200;
}

.webrtc .battle-comment-modal h4 {
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}

.webrtc .battle-comment-modal textarea {
  width: 100%;
  height: 200px;
  border: 2px solid #000;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
}

.webrtc .battle-comment-modal .block-check {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}

.webrtc .battle-comment-modal .block-check input {
  margin: 0 !important;
  width: 18px;
  height: 18px;
}

.webrtc .battle-comment-modal .block-check label {
  margin-left: 10px;
}

.webrtc .battle-comment-modal .button-group {
  display: flex;
  align-items: center;
  padding-top: 50px;
}

.webrtc .battle-comment-modal .button-group button {
  margin: 0;
}

.webrtc .battle-comment-modal .button-group .cancel-comment {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  color: #1d72b3;
  font-size: 15px;
}

.webrtc .battle-comment-modal ul {
  padding-left: 0;
}

.webrtc .battle-comment-modal h5 {
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}

.webrtc .battle-comment-modal ul li {
  margin-bottom: 6px;
  list-style: none;
}

.webrtc .battle-comment-modal ul li label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.webrtc .battle-comment-modal ul li label input {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 5px;
}

.webrtc .battle-comment-modal .button-group .send-comment {
  margin-right: 30px;
  color: #ff0000;
  font-size: 16px;
}

.webrtc .battle-block-modal {
  position: fixed;
  width: 700px;
  padding: 20px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 200;
}

.webrtc .battle-block-modal h4 {
  font-size: 15px;
  text-align: left;
  color: grey;
  margin-bottom: 20px;
}

.webrtc .battle-block-modal .block-check {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}

.webrtc .battle-block-modal .block-check input {
  margin: 0 !important;
  width: 18px;
  height: 18px;
}

.webrtc .battle-block-modal .block-check label {
  margin-left: 10px;
}

.webrtc .battle-block-modal .button-group {
  display: flex;
  align-items: center;
  padding-top: 50px;
}

.webrtc .battle-block-modal .button-group button {
  margin: 0;
}

.webrtc .battle-block-modal .button-group .cancel-comment {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  color: #1d72b3;
  font-size: 15px;
}

.webrtc .battle-block-modal .button-group .send-comment {
  margin-right: 30px;
  color: #ff0000;
  font-size: 16px;
}

.webrtc .mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 190;
}

/* スマホの画面 */
@media screen and (max-width: 768px) {
  .webrtc .battle-comment-modal {
    width: 90vw;
    max-height: 95vh;
    overflow: scroll;
  }

  .webrtc .battle-block-modal {
    width: 90vw;
    max-height: 95vh;
    overflow: scroll;
  }

  .webrtc .battle-comment-modal h4 {
    margin-top: 0;
  }

  .webrtc .battle-comment-modal .block-check label {
    flex: 1 1;
  }

  .webrtc .battle-comment-modal .block-check {
    align-items: flex-start;
  }
}
.battle-wait {
    min-height : 100vh;
    background-color: black;
    max-width: var(--max-width);
    margin: auto;
}

.battle-wait .title {
    color: white;
    padding-bottom: 0.4rem;
    letter-spacing: 0.8rem;
    border-bottom:dotted;
    border-width: 3px;
}

.battle-wait .loading {
    width: 25%;
    margin-top: 5rem;
}

@media only screen and (max-device-width: 480px) {
	.battle-wait .loading {
    width: 50%;
    margin-top: 5rem;
}
}
.battle-ready {
    min-height : 100vh;
    background-color: black;
    max-width: var(--max-width);
    margin: auto;
}
.battle-ready .title {
	font-size:3rem;
    color: white;
    border: none;
    padding-top: 15rem;
}
.battle-ready .button-start {
    display: block;
    width: 30%;
    margin: auto;
    margin-top: 5rem;
}

@media only screen and (max-device-width: 480px) {
.battle-ready .title {
    color: white;
    border: none;
    padding-top: 5rem;
}	
	.battle-ready .button-start {
    display: block;
    width: 60%;
    margin: auto;
    margin-top: 16rem;
}	
	
}
.format-select {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
}

.format-select .select-banner {
	width: 50%;
	margin-top: 4rem;
}

.format-select .format-list {
	list-style-type: none;
	padding: 0;
}

.format-select .format-list li, .format-select .format-list li img {
	display: block;
	width: 50%;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
}

.format-select .button-format {
    padding: 0.6rem 0;
    background-color: black;
    color: white;
    font-size: 2rem;
}

@media only screen and (max-device-width: 480px) {
	.format-select {
	min-height: 100vh;
	max-width: var(--max-width);
	margin: auto;
}

.format-select .select-banner {
	width: 86%;
	margin-top: 4rem;
}

.format-select .format-list {
	list-style-type: none;
	padding: 0;
}

.format-select .format-list li, .format-select .format-list li img {
	display: block;
	width: 80%;
	height: auto;
	cursor: pointer;
	margin: auto;
	margin-bottom: 2rem;
}

.format-select .button-format {
    padding: 0.6rem 0;
    background-color: black;
    color: white;
    font-size: 2rem;
}
}

.maintainance .notice {
    margin: 0 4rem;
    font-size: 1.6rem;
}
.auth {
    max-width: var(--max-width);
    margin: auto;
    text-align: center;
    align-items: center;
}
.auth .logo {
    width: 435px;
    margin-top: 3rem;
} 
.auth .form {
    margin-top: 3rem;
}
.auth .input {
    width: 50%;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}
.auth .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
}
.auth .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.auth .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.auth .footer .navi li {
    display: inline-block;
}
.auth .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.auth .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.auth .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.auth .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.auth .loading {
    font-size: 2.4rem;
    font-weight: bold;
}
.auth .error{
    font-size: 2.4rem;
    color: red;
}

@media only screen and (max-device-width: 480px) {
.auth .logo {
    width: 80%;
    margin-top: 3rem;
} 
}

.auth {
    max-width: var(--max-width);
    margin: auto;
    text-align: center;
    align-items: center;
}
.auth .logo {
    width: 435px;
    margin-top: 3rem;
} 
.auth .form {
    margin-top: 3rem;
}
.auth .input {
    width: 50%;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}
.auth .button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin: auto;
    margin-top: 3rem;
}
.auth .footer {
    background-color: black;
    color: white;
    font-size: 1.4rem;
    margin-top: 60rem;
    text-align: center;
}
.auth .footer .navi {
    margin: auto;
    padding-left: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
}
.auth .footer .navi li {
    display: inline-block;
}
.auth .footer .navi li.margin-right {
    margin-right: 1.5rem;
}
.auth .footer .navi li a.margin-right {
    margin-right: 1.5rem;
}
.auth .footer .copyright {
    width: 10rem;
    margin-bottom: 0.8rem;
}
.auth .beta {
    font-size: 2.4rem;
    font-weight: bold;
}
.auth .loading {
    font-size: 2.4rem;
    font-weight: bold;
}
.auth .error{
    font-size: 2.4rem;
    color: red;
}

@media only screen and (max-device-width: 480px) {
.auth .logo {
    width: 80%;
    margin-top: 3rem;
} 
}
.battle-ready {
    min-height : 100vh;
    background-color: black;
    max-width: var(--max-width);
    margin: auto;
}
.battle-ready .title {
	font-size:3rem;
    color: white;
    border: none;
    padding-top: 15rem;
}
.battle-ready .button-start {
    display: block;
    width: 30%;
    margin: auto;
    margin-top: 5rem;
}

@media only screen and (max-device-width: 480px) {
.battle-ready .title {
    color: white;
    border: none;
    padding-top: 5rem;
}	
	.battle-ready .button-start {
    display: block;
    width: 60%;
    margin: auto;
    margin-top: 16rem;
}	
	
}
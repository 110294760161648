:root {
  --max-width: 100%; 
  font-size: 62.5%;
}
li,
span,
pre,
h1,
h2,
h3,
h4,
h5,
p{
  color: var(--black);
}
a{
  text-decoration: none;
  color: inherit;
}
select,
button,
input[type="button"],
a {
cursor: pointer;
/* タブルタップでズームしない */
touch-action: manipulation;
font-family:-apple-system,BlinkMacSystemFont,"Helvetica Neue","游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif;
}
select:disabled,
button:disabled,
input[type="button"]:disabled,
a:disabled {
cursor: unset;
}
input[type="button"],
button{
outline: none;
background: none;
border: none;
}
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
body {
  /* max-width: var(--max-width); */
  min-height: 100vh;
  margin: auto;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  background-color: black;
}
button {
  display: block;
  margin: auto;
}

.title {
width: 24rem;
margin: auto;
padding-top: 8rem;
font-size: 2.4rem;
font-weight: bold;
border-bottom:solid;
border-width:3px;
}
.sub-title {
font-size: 1.6rem;
font-weight: bold;
letter-spacing: 0.2rem;
}
.description {
font-size: 1.4rem;
font-weight: bold;
letter-spacing: 0.1rem;
margin-top: 1.2rem;
}
.sankaku {
width: 8rem;
}
.button-normal,
.button-normal img {
  display: block;
  width: 37rem;
  margin: auto;
  margin-top: 2.6rem;
}
.button-back,
.button-back img {
  display: block;
  width: 27rem;
  margin: auto;
  margin-top: 4rem;
}
.required::after {
  content: "必須";
  color: #ffffff;
  background: #f22929;
  font-size: 0.7em;
  padding: 0.3em;
  border-radius: 0.5em;
  margin-left: 0.5em;
}



@media only screen and (max-device-width: 480px) {
.button-normal,
.button-normal img {
  display: block;
  width: 25rem;
  margin: auto;
  margin-top: 2.6rem;
}
.button-back,
.button-back img {
  display: block;
  width: 18rem;
  margin: auto;
  margin-top: 4rem;
}
}


@media only screen and (min-width: 768px) {
  a > img:hover,
  button > img:hover,
  .button-normal:hover,
  .card-list img:hover {
      opacity: .5;
      transition: .3s;
  }
}

@media only screen and (max-width: 768px) {
  a > img:active,
  button > img:active,
  .button-normal:active,
  .card-list img:active {
      opacity: .5;
      transition: .3s;
  }
}
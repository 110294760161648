.nickname {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.nickname input[type="text"] {
    width: 37rem;
    height: 3.2rem;
    border: none;
    border-radius: 0.4rem;
    font-size: 1.6rem;
}

@media only screen and (max-device-width: 480px) {
.nickname {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.nickname input[type="text"] {
    width: 22rem;
    height: 3.2rem;
    border: none;
    border-radius: 0.4rem;
    font-size: 1.6rem;
}	
}

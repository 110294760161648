.free-match {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.free-match .select-banner {
    width: 55%;
    margin-top: 4rem;
}
.free-match .card-list{
    list-style-type: none;
    padding: 0;
}
.free-match .card-list li,
.free-match .card-list li img {
    display: block;
    width: 50%;
    cursor: pointer;
    margin: auto;
    margin-bottom: 2rem;
}


@media only screen and (max-device-width: 480px) {
 .free-match {
    min-height : 100vh;
    max-width: var(--max-width);
    margin: auto;
}
.free-match .select-banner {
    width: 86%;
    margin-top: 4rem;
}
.free-match .card-list{
    list-style-type: none;
    padding: 0;
}
.free-match .card-list li,
.free-match .card-list li img {
    display: block;
    width: 80%;
    cursor: pointer;
    margin: auto;
    margin-bottom: 2rem;
}
}